import {
  useState,
  useMemo,
  ReactElement,
  useEffect,
  useLayoutEffect,
} from "react"
import styled from "styled-components"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"

import useDashboardAPI from "rest/useDashboardAPI"
import { formatMoney, lookup } from "libs/parse"

import Chart from "components/Chart"
import Card from "components/Card"
import Select from "components/Select"
import container from "components/Container"

import Summary from "./Summary"
//import { Data } from "./Data"
import { UST } from "constants/constants"

import AboutWebsiteMain from "components/AboutWebsiteMain"
import AboutWebsiteHeader from "components/AboutWebsiteHeader"

import { Display } from "react-7-segment-display"

const Wrapper = styled(container)`
  width: 100%;
  height: auto;
  position: relative;
  color: ${({ theme }) => theme.primary};
  z-index: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }

  .left {
    width: 1vw;
    float: left;
    margin-right: 10px;
  }
  .right {
    width: 1vw;
    float: right;
    margin-left: 10px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
    display: block;
    gap: unset;

    & > div {
      flex: unset;
      margin-bottom: unset;
    }

    .left {
      width: 100%;
      float: left;
      margin: unset;
    }

    .right {
      width: 100%;
      float: left;
      margin-left: 0px;
      margin-top: 20px;
    }
  }
`

const Footer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    opacity: 0.7;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
    flex-direction: column;
    justify-content: center;
  }
`

const Dashboard = () => {
  const navigate = useNavigate()
  const { api } = useDashboardAPI()
  const { data: pairs, isLoading: isPairsLoading } = useQuery(
    "pairs",
    api.pairs.list
  )

  const [selectedVolumeLength, setSelectedVolumeLength] = useState(7)
  const [selectedLiquidityLength, setSelectedLiquidityLength] = useState(7)

  const [autoRefreshTicker, setAutoRefreshTicker] = useState(false)

  let [currentSupply, setCurrentSupply] = useState(0)
  let [currentPrice, setCurrentPrice] = useState(0)
  let [currentReserve, setCurrentReserve] = useState(0)
  let [taxCollected, setTaxCollected] = useState(0)
  let [luna1Price, setLuna1Price] = useState(0)
  let [luna2Price, setLuna2Price] = useState(0)
  let [refreshKey, setRefreshKey] = useState(0)
  let [chartPoints, setChartPoints] = useState(0)
  let [latestPrice, setLatestPrice] = useState(0)
  const [lockedBase, setLockedBase] = useState(0)

  const { data: chart } = useQuery("baseswap_price", async () => {
    const timeNow = new Date(new Date().getTime())
    const res = await api.baseswap_price.getChartData()
    setChartPoints(res.length)
    return res
  })

  //quick hack to get the charts to show correctly
  useEffect(() => {
    // Fetch initial data for LUNC price
    const fetchInitialData = async () => {
      const url3 =
        "https://graphsv2.coinpaprika.com/currency/data/luna-terra/24h/?quote=usd"
      try {
        const response = await fetch(url3)
        const data = await response.json()
        const priceArray = data[0].price
        const latestData = priceArray[priceArray.length - 1]
        const [, price] = latestData
        setLuna1Price(Number(price))
      } catch (error) {
        console.error("Error fetching initial LUNC price:", error)
      }
    }

    fetchInitialData()

    // Quick hack to trigger chart update
    const timeoutId = setTimeout(() => {
      setSelectedVolumeLength((length) => length - 4) // Temporarily change the selectedVolumeLength
      setTimeout(() => setSelectedVolumeLength((length) => length + 4), 250) // Revert back after half second
    }, 500)

    // Cleanup timeout on unmount
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const timerId = setInterval(() => {
      if (window?.navigator?.onLine && window?.document?.hasFocus()) {
        setAutoRefreshTicker((current) => !current)
      }
    }, 30000)
    return () => {
      clearInterval(timerId)
    }
  }, [autoRefreshTicker, luna1Price, chart])

  useEffect(() => {
    const url =
      "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m/smart/eyJjdXJ2ZV9pbmZvIjp7fX0="
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        //console.log(text)
        const tbcData = JSON.parse(text)
        setCurrentSupply(Number(tbcData.data.supply) / 1000000)
        //setCurrentPrice(Number(tbcData.data.spot_price) / 1000000)
        setCurrentReserve(Number(tbcData.data.reserve) / 1000000)
        setTaxCollected(Number(tbcData.data.tax_collected) / 1000000)
      })

    //console.log(currentPrice)

    // Endpoint for the uluna balance (returns { balance: { denom: "uluna", amount: "127517933543464" } })
    const ulunaUrl =
      "https://terra-classic-lcd.publicnode.com/cosmos/bank/v1beta1/balances/terra12qc2mah0eyzsppcmekelqrhuxaa8jwwht0lv07xvv2t9wnepk58sg28yh8/by_denom?denom=uluna";

    // Endpoint for the contract balance (returns { data: { balance: "642215829410" } })
    const contractUrl =
      "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m/smart/eyJiYWxhbmNlIjp7ImFkZHJlc3MiOiJ0ZXJyYTEycWMybWFoMGV5enNwcGNtZWtlbHFyaHV4YWE4and3aHQwbHYwN3h2djJ0OXduZXBrNThzZzI4eWg4In19";

    // Fetch both endpoints concurrently
    Promise.all([
      fetch(ulunaUrl).then((response) => response.json()),
      fetch(contractUrl).then((response) => response.json())
    ])
      .then(([ulunaData, contractData]) => {
        // Convert the amounts from string to Number
        const ulunaAmount = Number(ulunaData.balance.amount);
        const contractBalance = Number(contractData.data.balance);

        // Both amounts are provided in "uluna" base units (e.g. micro-units),
        // so we divide each by 1,000,000 before computing the ratio.
        const ulunaValue = ulunaAmount / 1000000;
        const contractValue = contractBalance / 1000000;

        // The spot price is the ratio of uluna balance to the contract balance
        const computedSpotPrice = ulunaValue / contractValue;

        // Update the state with the computed spot price
        setCurrentPrice(computedSpotPrice);

        // If you still need to update other values from the original endpoint,
        // you can make that fetch separately and then update setCurrentSupply, etc.
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });


    const url3 =
      "https://graphsv2.coinpaprika.com/currency/data/luna-terra/24h/?quote=usd"
    fetch(url3)
      .then((response) => response.text())
      .then((text) => {
        //console.log(text)
        const apiCoinPaprikaLunc = JSON.parse(text)
        const priceArray = apiCoinPaprikaLunc[0].price
        const latestData = priceArray[priceArray.length - 1]
        const [timestamp, price] = latestData
        setLuna1Price(Number(price))
      })

    const url4 =
      "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m/smart/eyJiYWxhbmNlIjp7ImFkZHJlc3MiOiJ0ZXJyYTF5cWtweGRsZzNlMjRxMGF6dzdkdm5ua2FnNnF3MG01cHkydnlhNnY0NHF0c3hoaHR4eGdxM2xybXV1In19"
    fetch(url4)
      .then((response) => response.text())
      .then((text) => {
        //console.log(text)
        const data = JSON.parse(text)
        const balance = Number(data.data.balance)
        const processedBalance = balance / 1000000
        setLockedBase(processedBalance)
      })

    //handleRefresh();
    return
  }, [autoRefreshTicker])

  const selectedVolumeChart = useMemo(() => {
    if (currentPrice != 0 && luna1Price != 0 && chart != undefined) {
      const timeNow = new Date(new Date().getTime())
      if (chart?.length == chartPoints + 1) {
        chart.shift()
      }

      chart.splice(0, 0, {
        timestamp: timeNow,
        volumeUst: (currentPrice * luna1Price * 1000000).toFixed(6).toString(),
        liquidityUst: (currentPrice * 1000000).toFixed(6).toString(),
      })
    }

    return (chart || []).slice(0, selectedVolumeLength * 6)
  }, [chart, selectedVolumeLength, luna1Price, autoRefreshTicker])

  const topLiquidity = useMemo(() => {
    return undefined //do not use api
  }, [pairs])

  const topTrading = useMemo(() => {
    return undefined //do not use api
  }, [pairs])

  const restLiquidityUst = useMemo(() => {
    return undefined //do not use api
  }, [pairs])

  const restTradingUst = useMemo(() => {
    return undefined //do not use api
  }, [pairs])

  const dataForChild = [currentPrice * luna1Price, currentSupply]

  // Function to handle the refresh event
  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1)
  }

  return (
    <Wrapper>
      <Container>
        <AboutWebsiteHeader />
      </Container>

      <Container>
        <Summary
          data={[
            /*{
              label: "LUNC-USD",
              value: luna1Price.toString(),
              isCurrency: true,
              decimals: 6,
            },*/
            /*{
              label: "BASE-USD",
              value: (currentPrice * luna1Price).toString(),
              isCurrency: true,
              decimals: 6,
            },*/ //already shown on chart
            /*{
              label: "BASE-LUNC",
              value: ((currentPrice * luna1Price) / luna1Price).toString(),
              isCurrency: false,
              decimals: 6,
            },*/ //already shown on chart
            /*{
              label: "Market Cap",
              value: (currentSupply * currentPrice * luna1Price).toString(),
              isCurrency: true,
              decimals: 2,
            },
            {
              label: "TVL",
              value: (currentReserve * luna1Price).toString(),
              isCurrency: true,
              decimals: 2,
            },
            {
              label: "BASE Supply",
              value: currentSupply.toString(),
              isCurrency: false,
              decimals: 1,
            },
            {
              label: "LUNC Liquidity",
              value: currentReserve.toString(),
              isCurrency: false,
              decimals: 0,
            },
            /*{
              label: "Tax Collected",
              //value: `${taxCollected * luna2Price}`,
              value: `{taxCollected}`,
              isCurrency: true,
              decimals: 2,
            },*/
          ]}
        />

        {/*Uncomment to see charts rbh */}
        <Row>
          <Card className="left">
            <Row
              style={{
                marginBottom: 10,
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ flex: "unset", fontSize: 18, color: "#0d0d2b" }}>
                <b>BASE Price</b>
              </div>
              <div style={{ flex: "unset" }}>
                <Select
                  value={selectedVolumeLength}
                  onChange={(e) =>
                    setSelectedVolumeLength(parseInt(e?.target?.value, 10))
                  }
                >
                  {[540, 360, 270, 180, 90, 60, 30, 14, 7, 3].map((value) => (
                    <option key={value} value={value}>
                      {value} days
                    </option>
                  ))}
                </Select>
              </div>
            </Row>
            <div
              style={{
                fontSize: 18,
                fontWeight: 700,
                marginBottom: 14,
                color: "#0d0d2b",
              }}
            >
              ${(currentPrice * luna1Price).toFixed(6)}
              &nbsp;USD
            </div>
            <Chart
              key={refreshKey}
              type="line"
              height={178}
              data={selectedVolumeChart?.map((volume) => {
                return {
                  t: new Date(volume.timestamp),
                  y: Number(lookup(volume.volumeUst, UST)),
                }
              })}
            />
          </Card>

          {/*<Card className="right">
            <Row
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center", // Centers content vertically within the card
              width: "100%",           // Ensures the card's height is used
              height: "100%",           // Ensures the card's height is used
              minHeight: "200px",       // Optional: Set a minimum height for the card to prevent overflow
              padding: "20px",          // Optional: Add padding to prevent content from touching the edges
              boxSizing: "border-box",  // Ensures padding is included in the card's height
            }}
            >
              <div
              style={{
                flex: "unset", fontSize: 24, color: "#0d0d2b"
              }}
              >
                <b>LUNC Ratio:</b>
              </div>

              <div
              style={{
                fontSize: 48,
                color: "#0222ba",
                textAlign: "center",
              }}
              >
                <b>{(currentPrice).toFixed(6)}</b>
              </div>

              <br></br>

              <div
              style={{
                flex: "unset", fontSize: 24, color: "#0d0d2b"
              }}
              >
                <b>Locked BASE:</b>
              </div>

              <div
              style={{
                fontSize: 48,
                color: "#0222ba",
                textAlign: "center",
              }}
              >
                <b>{(currentPrice).toFixed(6)}</b>
              </div>
            </Row>
          </Card>*/}
        </Row>

        <Summary
          data={[
            /*{
              label: "LUNC Ratio",
              value: currentPrice.toFixed(6),
              isCurrency: false,
              decimals: 6,
            },
            {
              label: "Locked BASE",
              value: lockedBase.toFixed(6),
              isCurrency: false,
              decimals: 6,
            },*/
          ]}
        />

        <AboutWebsiteMain
          priceFromParent={dataForChild[0]}
          supplyFromParent={dataForChild[1]}
        />

        <Footer>
          <span>DASHBOARD IS FOR REFERENCE PURPOSES ONLY</span>
          <br></br>
          <span style={{ fontSize: "18px" }}>
            Multisig Wallet terra1sa86238scylhgajefgpl887hjyq4jf3u5ctl64
          </span>
          <br></br>
          <a
            className="main-header-navbar__nav__link"
            href="mailto: lbunproject@gmail.com"
          >
            Contact info: lbunproject@gmail.com
          </a>
        </Footer>
      </Container>
    </Wrapper>
  )
}

export default Dashboard
