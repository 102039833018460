import React, { useState, useEffect } from "react"
import "table.css"

function TerraClassicEscrows() {
  const [escrowIds, setEscrowIds] = useState([])
  const [escrowDetails, setEscrowDetails] = useState([])
  let [luna1Price, setLuna1Price] = useState(0)
  let [currentPrice, setCurrentPrice] = useState(0)

  useEffect(() => {
    // Fetch data from the first URL to get escrow IDs
    fetch(
      "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra17fcqmkf4vgdpz0y53z0j7qel6kjks3vrapwa6vxe6hw4scrau0es52gn7u/smart/eyJsaXN0Ijp7fX0="
    )
      .then((response) => response.json())
      .then((data) => {
        setEscrowIds(data.data.escrows)
      })
      .catch((error) => {
        console.error("Error fetching escrow IDs:", error)
      })
  }, [])

  useEffect(() => {
    // Fetch escrow details for each ID and store them in escrowDetails array
    const fetchEscrowDetails = async () => {
      const detailsPromises = escrowIds.map(async (id) => {
        const encodedId = btoa(JSON.stringify({ details: { id } }))
        const url = `https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra17fcqmkf4vgdpz0y53z0j7qel6kjks3vrapwa6vxe6hw4scrau0es52gn7u/smart/${encodedId}`

        try {
          const response = await fetch(url)
          const data = await response.json()
          return data.data
        } catch (error) {
          console.error(`Error fetching escrow details for ID ${id}:`, error)
          return null
        }
      })

      const details = await Promise.all(detailsPromises)
      setEscrowDetails(details.filter((detail) => detail !== null))
    }

    if (escrowIds.length > 0) {
      fetchEscrowDetails()
    }
  }, [escrowIds])

  function unixTimestampToDDMMMYY(unixTimestamp) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    const date = new Date(unixTimestamp * 1000) // Convert to milliseconds
    const dd = String(date.getDate()).padStart(2, "0") // Day
    const mmm = months[date.getMonth()] // Three-letter month abbreviation
    const yy = String(date.getFullYear()).slice(-2) // Last two digits of the year

    return `${dd} ${mmm} ${yy}`
  }

  function addCommasToNumber(number) {
    const parts = number.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return parts.join(".")
  }

  useEffect(() => {
    const url =
      "https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1uewxz67jhhhs2tj97pfm2egtk7zqxuhenm4y4m/smart/eyJjdXJ2ZV9pbmZvIjp7fX0="
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        const tbcData = JSON.parse(text)
        setCurrentPrice(Number(tbcData.data.spot_price) / 1000000)
      })

    return
  }, [])

  // Function to calculate remaining days
  const calculateRemainingDays = (endDate) => {
    const now = new Date()
    const end = new Date(endDate * 1000) // Convert to milliseconds
    const timeDiff = end - now
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
    return daysRemaining > 0 ? daysRemaining : 0
  }

  // Group escrows by IDs in sets of 7 using the MOD operator
  const groupEscrows = (escrows) => {
    const groups = {}
    escrows.forEach((escrow) => {
      const groupId = Math.floor(escrow.id / 7)
      if (!groups[groupId]) {
        groups[groupId] = []
      }
      groups[groupId].push(escrow)
    })
    return Object.values(groups)
  }

  // Calculate summarized data for each group
  const groupedData = groupEscrows(escrowDetails).map((group) => {
    const firstRow = group[0]
    const lastRow = group[group.length - 1]
    const combinedAmount = (firstRow.mature_balance * 7) / 1000000
    const vestStart = unixTimestampToDDMMMYY(firstRow.start_time)
    const releaseStart = unixTimestampToDDMMMYY(firstRow.end_time)
    const lastRelease = unixTimestampToDDMMMYY(lastRow.end_time)
    const nextReleaseDays =
      group
        .map((escrow) => calculateRemainingDays(escrow.end_time))
        .filter((days) => days > 0)
        .sort((a, b) => a - b)[0] || 0

    return {
      id: `${firstRow.id}-${lastRow.id}`,
      recipient: firstRow.recipient,
      combinedAmount,
      vestStart,
      baseReleasedPerMonth: firstRow.mature_balance / 1000000,
      releaseStart,
      lastRelease,
      nextReleaseDays,
    }
  })

  return (
    <div>
      <h1
        className="padded-header"
        style={{ textAlign: "center", fontSize: "28px" }}
      >
        BASE Vesting
      </h1>
      <h2
        className="padded-header"
        style={{ color: "#CD6141", textAlign: "center", fontSize: "14px" }}
      >
        1yr Vesting (Monthly releases start Month 6)
      </h2>
      <br></br>
      <div className="table-container">
        <div className="table-wrapper">
          <table className="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Recipient</th>
                <th>Total BASE</th>
                <th>Vest Start</th>
                <th>Released/Month</th>
                <th>Next Release</th>
                <th>Last Release</th>
                <th>Next Release (Days)</th>
              </tr>
            </thead>
            <tbody>
              {groupedData.map((group, index) => (
                <tr key={index}>
                  <td>{group.id}</td>
                  <td>{`${group.recipient.slice(
                    0,
                    7
                  )}...${group.recipient.slice(-4)}`}</td>
                  <td>{addCommasToNumber(group.combinedAmount)}</td>
                  <td>{group.vestStart}</td>
                  <td>{addCommasToNumber(group.baseReleasedPerMonth)}</td>
                  <td>{group.releaseStart}</td>
                  <td>{group.lastRelease}</td>
                  <td>{group.nextReleaseDays}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TerraClassicEscrows
